import { _classes } from "../../utilities/helpers";
import styles from "./Label.scss";

const cl = _classes(styles);

Label.propTypes = {
  value: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default function Label({ value, htmlFor, className }) {
  return value ? (
    <label htmlFor={htmlFor} className={cl(["root", className])}>
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </label>
  ) : null;
}
