import styles from "./SimpleCheckbox.scss";
import { _classes } from "../../utilities/helpers";

import { v4 as uuidv4 } from "uuid";
import Label from "../Label/Label";

const UUID = uuidv4();

const cl = _classes(styles);

SimpleCheckbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  borderColor: PropTypes.string,
  errorColor: PropTypes.string,
};

SimpleCheckbox.defaultProps = {
  className: "",
  defaultValue: "",
};

export default function SimpleCheckbox({
  name,
  label,
  className,
  borderColor,
  errorColor,
  onChange = () => {},
}) {
  const id = `${name}__${UUID}`;
  const errorId = `${name}__error__${UUID}`;

  return (
    <div className={cl(["field", "simple_checkbox", className])}>
      <div className={cl(["control"])}>
        <input
          id={id}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          type="checkbox"
          name={name}
          style={{ accentColor: borderColor }}
          aria-describedby={errorId}
        />
      </div>
      <Label className={cl("checkbox_label")} value={label} htmlFor={id} />
    </div>
  );
}
